import React from 'react'
import './Footer.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faFacebook, faPinterest } from "@fortawesome/free-brands-svg-icons"

export default () => (
  <div>
    <h2 className="taCenter">
      <span className="social">
      <a href="https://instagram.com/hahnesteats/"><FontAwesomeIcon size="lg" icon={faInstagram} /></a>
      </span>
      <span className="social">
      <a href="https://twitter.com/hahnesteats/"><FontAwesomeIcon size="lg" icon={faTwitter} /></a>
      </span>
      <span className="social">
      <a href="https://www.facebook.com/hahnesteats/"><FontAwesomeIcon size="lg" icon={faFacebook} /></a>
      </span>
      <span className="social">
      <a href="https://www.pinterest.com/hahnesteats/"><FontAwesomeIcon size="lg" icon={faPinterest} /></a>
      </span>
    </h2>
    <br />
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Crafted by{' '}
          <a href="https://hahnesteats.com/">Hahnest Eats</a>.
        </span>
      </div>
    </footer>
  </div>
)
